import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

export const MonthlyBox = styled.div`
  display: flex;
  margin-top: 50px;
  ${text.FS22}

  @media (max-width: 370px) {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
`;

interface IVariant {
  $choosed: boolean;
}

export const Variant = styled.span<IVariant>`
  font-weight: ${(props) =>
    props.$choosed ? props.theme.weights.bold : props.theme.weights.regular};
`;

import React, { useContext, useEffect, useState } from 'react';

import { AI } from '@/components/AI';
import { setUserEvent, userKey } from '@/components/Bridge';
import { Button, ButtonColors, Icon, Name } from '@/components/general';
import { LocalKeys } from '@/utils/constants';
import { PricingContext } from '../../Content';
import { ICardType } from '../../constants';
import * as styled from './Card.styled';

export enum PlanChapters {
  APP = 'app',
  WORKSPACE = 'workspace',
  BUSINESS = 'business',
}

interface ICard {
  card: ICardType;
  index: number;
  chapter: PlanChapters;
}

export const Card: React.FC<ICard> = ({ card, index, chapter }) => {
  const { appYearly } = useContext(PricingContext);
  const yearly = chapter === PlanChapters.APP ? appYearly : false;
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    setAuthorized(!!sessionStorage.getItem(userKey));
    const onSetUser = () => setAuthorized(!!sessionStorage.getItem(userKey));
    document.addEventListener(setUserEvent, onSetUser);
    return () => document.removeEventListener(setUserEvent, onSetUser);
  }, []);

  const button = (
    <Button
      height={36}
      color={
        index === 0 || chapter === PlanChapters.WORKSPACE
          ? ButtonColors.PRIMARY
          : index === 1
          ? ButtonColors.SECONDARY
          : ButtonColors.BLACK
      }
      onClick={() => localStorage.setItem(LocalKeys.PRICING_PLAN, card.title)}
      to={card.getButtonLink(yearly)}
    >
      {card.buttonText}
    </Button>
  );

  return (
    <styled.Box $index={index} $chapter={chapter}>
      <styled.Title>{card.title}</styled.Title>
      <styled.Price>
        {card.price !== undefined
          ? yearly
            ? `$${card.yearPrice}`
            : `$${card.price}`
          : card.priceText}
      </styled.Price>
      <styled.Description>
        {card.description ||
          (chapter === PlanChapters.APP &&
            `Per month / billed ${appYearly ? 'yearly' : 'monthly'}`)}
      </styled.Description>
      <styled.ButtonBox>
        {!authorized && card.ai ? (
          <AI
            intent="start-building"
            planPriceId={card.getPlanPriceId?.(yearly)}
          >
            {button}
          </AI>
        ) : (
          button
        )}
      </styled.ButtonBox>
      <styled.FeaturesHeading>{card.featuresHeading}</styled.FeaturesHeading>
      <styled.FeaturesList>
        {card.features.map((feature) => (
          <styled.ListItem key={feature}>
            <styled.VoteBox>
              <Icon name={Name.GENERAL_PLANS_CHECK_MARK} />
            </styled.VoteBox>
            <styled.ItemText>{feature}</styled.ItemText>
          </styled.ListItem>
        ))}
      </styled.FeaturesList>
    </styled.Box>
  );
};

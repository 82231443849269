import React, { createContext, useMemo, useState } from 'react';

import gradient from '@/images/Pricing/gradient.png';

import * as index from '@/components/Sections/index.styled';
import { Links } from '@/utils/constants';
import * as styled from './Content.styled';
import { Title, UnderTitle } from '../general/FormPages.styled';

import { Breadcrumbs, Layout, Feature } from '../general';
import { Card, PlanChapters, PlanPeriodSwitch, Table } from './components';
import {
  APP_PLANS,
  BUSINESS_PLANS,
  FEATURES,
  WORKSPACE_PLANS,
} from './constants';

export const PricingContext = createContext(null);

export const Content: React.FC = () => {
  const [appYearly, setAppYearly] = useState(false);

  const pricingMemo = useMemo(
    () => ({ appYearly, setAppYearly }),
    [appYearly, setAppYearly],
  );

  return (
    <PricingContext.Provider value={pricingMemo}>
      <Layout>
        <Breadcrumbs links={[Links.PRICING]} />
        <styled.TopBox>
          <Title>App plans</Title>
          <UnderTitle>
            Build, publish, and host native apps with ease.
          </UnderTitle>
          <PlanPeriodSwitch />
        </styled.TopBox>
        <styled.CardsBox>
          {APP_PLANS.map((card, index) => (
            <Card
              key={card.title}
              card={card}
              index={index}
              chapter={PlanChapters.APP}
            />
          ))}
        </styled.CardsBox>
        <styled.TableBox>
          <Table />
        </styled.TableBox>
        <styled.TeamBox>
          <div>
            <Title style={{ color: '#EA3368' }}>Workspace plans</Title>
            <UnderTitle>Collaborate effectively on all your apps.</UnderTitle>
            <styled.CardsBox>
              {WORKSPACE_PLANS.map((card, index) => (
                <Card
                  key={card.title}
                  card={card}
                  index={index}
                  chapter={PlanChapters.WORKSPACE}
                />
              ))}
            </styled.CardsBox>
          </div>
          <styled.Gradient src={gradient} />
        </styled.TeamBox>
        <styled.BusinessBox>
          <Title style={{ color: '#F1A23B' }}>Business plans</Title>
          <UnderTitle>
            Custom development and partnership programs tailored to your needs.
          </UnderTitle>
          <styled.CardsBox>
            {BUSINESS_PLANS.map((card, index) => (
              <Card
                key={card.title}
                card={card}
                index={index}
                chapter={PlanChapters.BUSINESS}
              />
            ))}
          </styled.CardsBox>
        </styled.BusinessBox>
        <styled.FAQSection>
          <index.Title>Still have questions?</index.Title>
          <index.FAQBox>
            {FEATURES.map((feature) => (
              <Feature
                key={feature.question}
                question={feature.question}
                answer={feature.answer}
                linkReg={feature.linkReg}
                links={feature.links}
                style={{ borderRadius: '16px' }}
                $mainPage
              />
            ))}
          </index.FAQBox>
        </styled.FAQSection>
      </Layout>
    </PricingContext.Provider>
  );
};

import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

import { PlanChapters } from './Card';

export const Title = styled.h3`
  ${text.FS20}
  font-weight: ${(props) => props.theme.weights.bold};
  line-height: normal;
  margin-bottom: 15px;
`;

export const Price = styled.div`
  ${text.FS30}
  font-weight: ${(props) => props.theme.weights.bold};
  line-height: normal;
  margin-bottom: 5px;
`;

export const Description = styled.div`
  ${text.FS18}
  font-weight: ${(props) => props.theme.weights.regular};
  line-height: normal;
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.grayText};
`;

export const ButtonBox = styled.div`
  margin-bottom: 20px;
  & > * {
    width: 100%;
  }
`;

export const FeaturesHeading = styled.p`
  ${text.FS18}
  font-weight: ${(props) => props.theme.weights.bold};
  line-height: normal;
  margin-bottom: 15px;
  padding-top: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorderColor};
`;

export const FeaturesList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ListItem = styled.li`
  display: flex;
  gap: 5px;
`;

export const VoteBox = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
`;

export const ItemText = styled.div`
  margin-top: 2px;
  font-size: 14px;
  font-weight: ${(props) => props.theme.weights.semiBold};
  line-height: 145%;
`;

interface IBox {
  $index: number;
  $chapter: PlanChapters;
}

export const Box = styled.div<IBox>`
  width: 350px;
  padding: 30px;
  border-radius: 24px;
  will-change: transform;
  transition: ${(props) => props.theme.transition.scrollAnimation};
  transform: translateY(0px);
  border: 1px solid transparent;
  background-color: ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.black};
  ${(props) => {
    switch (props.$chapter) {
      case PlanChapters.APP:
        switch (props.$index) {
          case 1:
            return `
        background-color: ${props.theme.colors.blue};
        color: ${props.theme.colors.white};
        ${Description} {
          color: ${props.theme.colors.offWhite};
        }
        ${VoteBox} {
          & path {
            stroke: ${props.theme.colors.white};
          }
        }
        `;
          case 2:
            return `
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.borderColor};
        `;
        }
        break;
      case PlanChapters.WORKSPACE:
        return `
          background-color: ${props.theme.colors.white};
          border: 1px solid ${props.theme.colors.borderColor};
        `;
      case PlanChapters.BUSINESS:
        switch (props.$index) {
          case 1:
            return `
          background-color: ${props.theme.colors.white};
          border: 1px solid ${props.theme.colors.borderColor};
            ${ButtonBox} {
              & a, & button {
                border-color: #F1A23B;
                background-color: #F1A23B;
                &:hover {
                  background-color: #E2932C;
                }
                & > span {
                  color: ${props.theme.colors.white};
                }
              }
            }
            `;
        }
        break;
    }
  }}

  &:hover {
    transform: translateY(-10px);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    width: 500px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

import styled from 'styled-components';

import * as index from '@/components/Sections/index.styled';

export const TopBox = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 50px;
  }
`;

export const CardsBox = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
  column-gap: 20px;
  position: relative;
  z-index: 12;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
  }
`;

export const TableBox = styled.div`
  width: 100%;
  margin-top: 30px;
  position: relative;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const TeamBox = styled.div`
  width: 100%;
  margin-top: 100px;
  position: relative;
  z-index: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 75px;
  }

  & > div {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Gradient = styled.img`
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-56%);
  z-index: 1;
  pointer-events: none;
`;

export const BusinessBox = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 75px;
  }
`;

export const FAQSection = styled(index.FAQSection)`
  margin-top: 100px;
  margin-bottom: 0px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 100px;
    margin-bottom: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-top: 70px;
  }
`;

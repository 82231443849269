import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

export const Title = styled.h1`
  ${text.FS50}
  text-align: center;
  margin-bottom: 20px;
`;

export const UnderTitle = styled.p`
  ${text.FS17_NOT_CHANGE}
  max-width: 655px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    font-size: 15px;
    & br {
      display: none;
    }
  }
`;

export const BlurBox = styled.div`
  position: absolute;
  top: 25px;
  left: -38vw;
  width: 179vw;
  aspect-ratio: 1/0.2;
  min-height: 400px;
  max-height: 507px;
  border-radius: 179vw;
  opacity: 0.8;
  background: rgba(115, 123, 248, 0.15);
  filter: blur(100px);
  will-change: transform;
  z-index: 11;
  pointer-events: none;
`;

import React from 'react';

import * as styled from './Table.styled';

export const TableHead: React.FC = () => (
  <styled.TableHead>
    <div>
      <b>App plans</b>
    </div>
    <div>
      <b>Professional</b>
    </div>
    <div>
      <b>Business</b>
    </div>
    <div>
      <b>Custom</b>
    </div>
  </styled.TableHead>
);

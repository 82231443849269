import React from 'react';

import { Icon, Name, Popover } from '@/components/general';
import * as styled from './Table.styled';
import { ITableData } from './constants';

interface ITableBody {
  section: ITableData;
}

export const TableBody: React.FC<ITableBody> = ({ section }) => (
  <styled.Table>
    <h2>{section.title}</h2>
    {section.rows.map((el, index) => (
      <styled.TableRow key={`${section.title}-${index}`}>
        <div>
          <span>{el.name}</span>
          {!!el.description && <Popover text={el.description} />}
        </div>
        {['professional', 'business', 'custom'].map((key) => (
          <div key={key}>
            {typeof el[key] === 'string' ? (
              <span>{el[key]}</span>
            ) : el[key] ? (
              <Icon name={Name.GENERAL_PRICING_TABLE_CHECK_MARK} />
            ) : (
              <Icon name={Name.GENERAL_PRICING_TABLE_CROSS} />
            )}
          </div>
        ))}
      </styled.TableRow>
    ))}
  </styled.Table>
);

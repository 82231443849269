import React, { useEffect, useContext } from 'react';

import { Switch } from '@/components/general';
import { LocalKeys } from '@/utils/constants';
import * as styled from './PlanPeriodSwitch.styled';
import { PricingContext } from '../../Content';

export const PlanPeriodSwitch: React.FC = () => {
  const { appYearly, setAppYearly } = useContext(PricingContext);

  useEffect(() => {
    localStorage.setItem(LocalKeys.APP_PERIOD, appYearly ? 'year' : 'month');
  }, [appYearly]);

  return (
    <styled.MonthlyBox>
      <styled.Variant $choosed={!appYearly}>Monthly</styled.Variant>
      <Switch
        state={appYearly}
        setState={setAppYearly}
        style={{ margin: '0 15px' }}
      />
      <span>
        <styled.Variant $choosed={appYearly}>Yearly</styled.Variant>
        &nbsp;(save up to 20%)
      </span>
    </styled.MonthlyBox>
  );
};

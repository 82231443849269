import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

export const TableContainer = styled.div<{ open: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    ${text.FS17}
    margin-bottom: 70px;
  }
  & > button {
    margin-bottom: ${(props) => (props.open ? '40px' : '0px')};
    height: 50px;
    width: 205px;
    ${text.FS14}
    line-height: 100%;
    font-weight: ${(props) => props.theme.weights.bold};
    border: 1px solid ${(props) => props.theme.colors.blue};
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${(props) => props.theme.transition.primary};
    &:hover {
      background-color: ${(props) => props.theme.colors.primaryBackground};
    }
    & div {
      margin-left: 10px;
      transition: ${(props) => props.theme.transition.primary};
      transform: ${(props) => `rotate(${props.open ? '180deg' : '0deg'})`};
      & svg path {
        fill: ${(props) => props.theme.colors.blue};
      }
    }
  }
`;

export const TableHead = styled.div`
  display: flex;
  padding: 10px 15px;
  border-radius: 8px;
  background: linear-gradient(90deg, #e6e9f0 0%, #eef1f5 100%);
  font-weight: 400;
  & > div {
    width: calc(100% / 4);
    color: ${(props) => props.theme.colors.darkText};
    & b {
      font-weight: 700;
    }
  }
`;

export const Table = styled.div`
  margin: 0 15px 20px;
  & h2 {
    color: ${(props) => props.theme.colors.grayText};
    font-size: 22px;
    line-height: 26.4px;
    font-weight: 700;
    margin: 40px 0 5px;
  }
`;

export const TableRow = styled.div`
  display: flex;
  gap: 15px;
  padding: 15px 0;
  color: ${(props) => props.theme.colors.darkText};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  & > div {
    width: calc(100% / 4);
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    &:first-child {
      font-weight: 700;
    }
    & svg {
      min-width: 20px;
      min-height: 20px;
    }
    & span {
      & > mark {
        color: ${(props) => props.theme.colors.grayText};
        background: transparent;
      }
    }
  }
`;

export const TableBox = styled.div<{ height: number }>`
  width: 100%;
  max-width: 1135px;
  margin: 0 auto;
  font-size: 17px;
  line-height: 20.4px;
  height: ${(props) => props.height}px;
  opacity: ${(props) => (props.height ? '1' : '0')};
  overflow: hidden;
  transition: ${(props) => props.theme.transition.primary};
`;

export const TableRefBox = styled.div``;

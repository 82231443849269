import React, { useEffect, useRef, useState } from 'react';

import { Icon, Name } from '@/components/general';
import * as styled from './Table.styled';

import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import { TABLE_DATA } from './constants';

export const Table: React.FC = () => {
  const tableRef = useRef<HTMLDivElement | null>(null);

  const [tableHeight, setTableHeight] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const onManageTableVisibility = () => {
    if (open) {
      setTableHeight(0);
    } else if (tableRef?.current) {
      setTableHeight(tableRef.current.offsetHeight);
    }
    setOpen(!open);
  };

  useEffect(() => {
    const onResize = () => () => {
      if (open) {
        if (tableRef?.current) {
          setTableHeight(tableRef.current.offsetHeight);
        }
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <styled.TableContainer open={open}>
      <p>
        All prices are charged per project, with applicable taxes added at
        checkout.
      </p>
      <button type="button" onClick={onManageTableVisibility}>
        {open ? 'Hide plan features' : 'View all plan features'}
        <Icon name={Name.GENERAL_FEATURE_ARROW} width={14} />
      </button>
      <styled.TableBox height={tableHeight}>
        <styled.TableRefBox ref={tableRef}>
          <TableHead />
          {TABLE_DATA.map((section) => (
            <TableBody key={section.title} section={section} />
          ))}
        </styled.TableRefBox>
      </styled.TableBox>
    </styled.TableContainer>
  );
};
